
import React, { useState } from "react";
import * as Icon from 'react-feather';
import { Col, Container, Row } from "reactstrap";
import { Link } from 'react-router-dom';
import ModalVideo from "react-modal-video";

import BackgroundImage1 from "../assets/images/bg/hero-bg.jpg";

export default function SectionHome() {
    const [videoModal, setVideoModal] = useState(false);

    const openModal = () => {
        setVideoModal(true);
    };

    return (
        <>
            <section className="bg-home d-flex align-items-center" style={{ background: `url(${BackgroundImage1})` }} id="home">
                    <div className="bg-overlay bg-linear-gradient-3"></div>
                    <Container>
                        <Row>
                            <Col>
                                <div className="title-heading">
                                    <h1 className="heading text-white title-dark mb-4">Pemberdayaan Usaha Lokal <br /> untuk Ketahanan Pangan</h1>
                                    <p className="para-desc text-white-50">Kolaborasi teknologi dalam upaya memperkuat rantai pasok dan hubungan ekonomi antara Desa dan Kota.</p>
                                    <div className="mt-4 pt-2">
                                        <Link to="#" className="btn btn-primary m-1">Mari Bergabung</Link>
                                        <Link to="#" data-type="youtube" data-id="yba7hPeTSjk" onClick={openModal} className="btn btn-icon btn-pills btn-primary m-1 lightbox"><Icon.Video className="icons" /></Link><span className="text-uppercase text-white-50 small align-middle ms-2">Lihat Video</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
            </section>
            {/* <ModalVideo
                channel="youtube"
                isOpen={videoModal}
                videoId="yba7hPeTSjk"
                onClose={() => setVideoModal(false)}
            /> */}
        </>
    )
}
