import React from "react";
import { Col, Container, Card, Row } from "reactstrap";
import { Link } from "react-router-dom";
import AppDevelopment from '../assets/images/bg/app-development.jpg';
import CTA from '../assets/images/bg/nadi-cta.jpg';

export default function SectionSolution() {
    const services = [
        {
            id: 'service1',
            icon: 'airplay',
            title: "Sistem Manajemen Stok",
            description: "Menyediakan teknologi untuk mengelola penjualan masuk dan keluar, memudahkan Kios Tani dalam pengelolaan stok kios."
        },
        {
            id: 'service2',
            icon: 'circle-layer',
            title: "Distribusi Yang Terintegrasi",
            description: 'Menghubungkan Kios Tani secara digital dengan Produsen dan Distributor, memperkuat kerjasama dan efisiensi pengiriman barang.'
        },
        {
            id: 'service3',
            icon: 'flip-h',
            title: "Tenaga Canvaser",
            description: 'Menyediakan layanan pendukung untuk menjangkau lebih banyak petani di area layanan kios tani.'
        }
    ];

    return (
        <>
          <section className="section bg-light" id="solution">
                <Container>
                    <Row className="justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center mb-4 pb-2">
                                <h3 className="title mb-3">Sistem Rantai Pasok Terintegrasi</h3>
                                <p className="text-muted mx-auto para-desc mb-0">Penguatan rantai pasok agri input melalui digitalisasi dan integrasi dengan layanan finansial telah memberikan dampak positif yang besar bagi Kios Tani dan petani.</p>
                            </div>
                        </div>
                    </Row>

                    <Row>
                        {services.map((item, key) => (
                            <Col lg={4} md={6} className="mt-5 pt-4" key={key}>
                                <Card className="features feature-primary feature-shadow-md shadow rounded p-4 pt-5">
                                    <div className="icon-style mt-n80 position-relative text-center shadow rounded bg-white mb-4">
                                        <i className={"h4 mb-0 uil uil-" + item.icon}></i>
                                    </div>
                                    <div className="content">
                                        <Link to="#" className="title text-dark h5">{item.title}</Link>
                                        <p className="text-muted mt-3">{item.description}</p>
                                    </div>
                                </Card>
                            </Col>
                        ))}

                    </Row>
                    <div className="container mt-100 mt-60">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="me-lg-5">
                                    <div className="row justify-content-center">
                                        <div className="col-12">
                                            <div className="section-title text-center text-md-start mb-4 pb-2">
                                                <h4 className="title mb-3">Optimalkan Usaha Pertanian Anda</h4>
                                                <p className="para-desc text-muted mx-auto mb-0">Rasakan kemudahan mengelola pertanian dengan aplikasi pintar yang dirancang untuk masa depan pertanian berkelanjutan.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <Row>
                                        <div className="col-md-12 mt-4 pt-2">
                                            <div className="features feature-primary rounded border-0 d-flex">
                                                <div className="feature-icon sm-icon text-center">
                                                    <i className="rounded-pill">1</i>
                                                </div>

                                                <div className="content flex-1 ms-3">
                                                    <Link to="#" className="title h5 text-dark">Inisiasi Digital</Link>
                                                    <p className="text-muted mt-2 mb-0">Kami menggabungkan teknologi terdepan dengan kearifan lokal untuk membawa usaha tani Anda ke tingkat berikutnya dengan efisiensi dan skalabilitas.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 mt-4 pt-2">
                                            <div className="features feature-primary rounded border-0 d-flex">
                                                <div className="feature-icon sm-icon text-center">
                                                    <i className="rounded-pill">2</i>
                                                </div>

                                                <div className="content flex-1 ms-3">
                                                    <Link to="#" className="title h5 text-dark">Evolusi Operasional</Link>
                                                    <p className="text-muted mt-2 mb-0">Dari pemantauan stok sampai pengiriman, aplikasi kami mempermudah setiap langkah - memberikan Anda kebebasan untuk berkonsentrasi pada pertumbuhan usaha.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 mt-4 pt-2">
                                            <div className="features feature-primary rounded border-0 d-flex">
                                                <div className="feature-icon sm-icon text-center">
                                                    <i className="rounded-pill">3</i>
                                                </div>

                                                <div className="content flex-1 ms-3">
                                                    <Link to="#" className="title h5 text-dark">Demonstrasi Langsung</Link>
                                                    <p className="text-muted mt-2 mb-0">Dapatkan pengalaman langsung dengan aplikasi kami dan saksikan bagaimana fitur-fitur intuitif kami dapat mempercepat dan menyempurnakan operasional harian Anda.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </div>

                            <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="img-fluid-responsive position-relative">
                                    <img src={AppDevelopment} className="mx-auto d-block" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
          </section>
          <section className="section" style={{ background: `url(${CTA}) center` }}>
                    <div className="bg-overlay"></div>
                    <Container>
                        <div className="row justify-content-center">
                            <div className="col">
                                <div className="section-title text-center">
                                    <h4 className="title text-white mb-3">Siap Mengembangkan Usaha Anda Bersama Kami?</h4>
                                    <p className="text-white-50 mx-auto para-desc mb-0">Dukung upaya kami dalam mengadvokasi usaha lokal untuk stabilitas pangan. Manfaatkan kemajuan teknologi dan inovasi kami untuk membuka akses pasar yang lebih luas dan berkelanjutan.</p>

                                    <div className="mt-4 pt-2">
                                        <Link to="https://play.google.com/store/apps/details?id=com.optima.nadi" className="btn btn-primary">Unduh Sekarang !</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
         </section>
        </>
    )
};