import React, { useState, useEffect } from 'react';
import {
    Nav,
    NavbarBrand,
    NavbarToggler,
    Collapse,
    NavItem,
} from "reactstrap";

import * as Icon from 'react-feather';
import { Link } from 'react-scroll';

import SectionHome from './component/SectionHome';
import SectionAboutUs from './component/SectionAboutUs';
import SectionSolution from './component/SectionSolution';
import SectionProject from './component/SectionProject';
import SectionTestimonial from './component/SectionTestimonial';
import SectionContactUs from './component/SectionContactUs';
import Footer from './component/Footer';

import Logodark from "./assets/images/logo-dark.png";
import Logolight from "./assets/images/logo-light.png";

export default function Agency() {
    const [isMenuOpen, setMenu] = useState(true);
    const [arrow, setArrow] = useState(false);

    const handleScroll = () => {
        if (
            document.body.scrollTop >= 500 ||
            document.documentElement.scrollTop >= 500
        ) {
            setArrow(true);
        } else {
            setArrow(false);
        }
    };

    const windowScroll = () => {
        const navbar = document.getElementById('navbar');
        if (
            document.body.scrollTop >= 50 ||
            document.documentElement.scrollTop >= 50
        ) {
            navbar.classList.add('nav-sticky');
        } else {
            navbar.classList.remove('nav-sticky');
        }
    };

    const toggleMenu = () => {
        setMenu(!isMenuOpen)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('scroll', windowScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('scroll', windowScroll);
        };
    }, []);

    return (
        <>
            <div>
                {/* <ScrollTo> */}
                <nav id="navbar" className="navbar navbar-expand-lg nav-light fixed-top sticky">
                    <div className="container">
                        <NavbarBrand className="navbar-brand" href="/">
                            <span className="logo-light-mode">
                                <img src={Logolight} className="l-light" alt="" height={38}/>
                                <img src={Logodark} className="l-dark" alt="" height={38}/>
                            </span>
                            <img src={Logolight} className="logo-dark-mode" alt="" />
                        </NavbarBrand>
                        <NavbarToggler onClick={toggleMenu}>
                            <Icon.Menu />
                        </NavbarToggler>

                        <Collapse className={`navbar-collapse ${isMenuOpen === true ? 'hidden' : 'show'}`} id="navbarSupportedContent">
                            <Nav className="navbar-nav ms-auto mb-2 mb-lg-0" id="navbar-navlist">
                                <NavItem>
                                    <Link activeClass="active" to="home" spy={true} smooth={true} duration={500} className="nav-link">Beranda</Link>
                                </NavItem>
                                <NavItem>
                                    <Link activeClass="active" to="about-us" spy={true} smooth={true} duration={500} className="nav-link">Tentang Kami</Link>
                                </NavItem>
                                <NavItem>
                                    <Link activeClass="active" to="solution" spy={true} smooth={true} duration={500} className="nav-link">Solusi</Link>
                                </NavItem>
                                <NavItem>
                                    <Link activeClass="active" to="project" spy={true} smooth={true} duration={500} className="nav-link">Kinerja</Link>
                                </NavItem>
                                <NavItem>
                                    <Link activeClass="active" to="testimonial" spy={true} smooth={true} duration={500} className="nav-link">Testimoni</Link>
                                </NavItem>
                                <NavItem>
                                    <Link activeClass="active" to="contact" spy={true} smooth={true} duration={500} className="nav-link">Kontak Kami</Link>
                                </NavItem>
                            </Nav>
                        </Collapse>

                    </div>

                </nav>

                <SectionHome />
                <SectionAboutUs />
                <SectionSolution />
                <SectionProject />
                <SectionTestimonial />
                <SectionContactUs />
                
                <Footer />
                <Link to="home" style={{ display: arrow === true ? 'block' : 'none' }} id="back-to-top" className="back-to-top rounded-pill fs-5"><Icon.ArrowUp className="fea icon-sm icons align-middle" /></Link>
            </div>
        </>
    )
};