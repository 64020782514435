
import React, { useState } from "react";
import { Col, Container, Row, Card } from "reactstrap";
import { Link } from 'react-router-dom';
import ModalVideo from "react-modal-video";

import MapImage from '../assets/images/map.png';
import AboutImage from '../assets/images/hero-about.jpg';

export default function SectionAboutUs() {
    const [videoModal, setVideoModal] = useState(false);

    const openModal = () => {
        setVideoModal(true);
    };

    const strenghts = [
        {
            id: 1,
            title: 'Ruang Gudang',
            value: '180.000 m²',
            description: 'Mengelola ruang inventaris yang luas dan berpotensi terhubung dengan 9.000 Kiosk Tani di 119 kota.'
        },
        {
            id: 2,
            title: 'Kiosk Tani Digital',
            value: '200+',
            description: 'Lebih dari 200 Kiosk Tani terkoneksi digital, memudahkan distribusi dan akses ke sarana pertanian.'
        },
        {
            id: 3,
            title: 'Mitra Grosir',
            value: '50+',
            description: 'Mitra grosir yang terhubung digital, memperkuat jaringan pasokan ke berbagai wilayah.'
        },
        {
            id: 4,
            title: 'Varian Produk',
            value: '1.500+ SKU',
            description: 'Menyediakan lebih dari 1.500 Stock Keeping Unit (SKU), memastikan ketersediaan produk yang beragam dan lengkap.'
        },
    ];

    return (
        <>
            <section className="section" id="about-us">
                    <Container>
                        <div style={{ background: `url(${MapImage}) center center` }}>
                            <Row className="align-items-center">
                                <Col lg={6} md={6}>
                                    <div className="position-relative me-lg-5">
                                        <img src={AboutImage} className="rounded img-fluid mx-auto d-block" alt="" />
                                        <div className="play-icon">
                                            <Link to="#" onClick={openModal} data-type="youtube" data-id="yba7hPeTSjk" className="play-btn lightbox">
                                                <i className="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                                    <div className="section-title">
                                        <h4 className="title mb-3">Menciptakan Ekosistem Rantai Pasok Pupuk <br /> yang Efisien dan Ramah Petani</h4>
                                        <p className="text-muted">Didirikan pada tahun 2021, Nadi berawal dari tangan-tangan terampil para profesional berpengalaman di bidang Manajemen Rantai Pasok, Kecerdasan Lokasi, dan Finansial. Kami bertekad untuk memperbarui sektor agri input mulai dari hulu hingga ke hilir (Produsen - Distributor - Kiosk Tani), dengan cita-cita besar untuk terus menyatukan mereka dengan kedalam ekosistem digital yang inklusif.</p>
                                        <p className="text-muted">Kami bekerja sama dengan ratusan grosir dan distributor untuk melayani ribuan kios petani dalam mendistribusikan pupuk, obat-obatan tanaman dan benih secara digital. Inisiatif ini sangat penting bagi ratusan ribu petani yang akan memulai penanaman dan pemeliharaan tanaman pangan.</p>
                                        <p className="text-muted">Digitalisasi rantai pasok dan akses pembiayaan telah membawa dampak besar bagi Kiosk Tani dan juga memberikan efek langsung dalam mengatasi masalah ketersediaan faktor input pertanian (pupuk, pestisida, benih, dll) di tingkat petani.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="equal-height-cards">
                                {strenghts.map((item, key) => (
                                    <Col lg={3} md={6} className="col-12 mt-4 pt-2" key={key}>
                                    <Card className="border-0 shadow d-flex flex-column h-100"> {/* Add h-100 for full height */}
                                    <div className="p-4">
                                    <span className="price h3 fw-semibold mb-0">{item.value}</span>
        <h6 className="fw-semibold mb-3 text-uppercase">{item.title}</h6>
        <p className="text-muted flex-grow-1">{item.description}</p>
      </div>
                                    </Card>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Container>
                </section>
            {/* <ModalVideo
                channel="youtube"
                isOpen={videoModal}
                videoId="yba7hPeTSjk"
                onClose={() => setVideoModal(false)}
            /> */}
        </>
    )
}
