import React from 'react';
import { Link } from "react-router-dom";

export default function Footer() {


        return (
            <>
                <footer className="bg-footer">
                    <div className="footer-py-30 footer-bar">
                        <div className="container text-center text-white">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-sm-8">
                                    <div className="text-sm-start">
                                        <p className="mb-0">© {(new Date().getFullYear())}{" "} 2024 PT. Ananta Nadi Nusantara. All rights reserved.</p>
                                    </div>
                                </div>

                                <div className="col-sm-4 mt-4 mt-sm-0">
                                    <ul className="list-unstyled social-icon text-sm-end foot-social-icon mb-0">
                                        <li className="list-inline-item ms-1"><Link to="#" className="rounded"><i className="uil uil-linkedin" title="Linkedin"></i></Link></li>
                                        <li className="list-inline-item ms-1"><Link to="#" className="rounded"><i className="uil uil-facebook-f align-middle" title="facebook"></i></Link></li>
                                        <li className="list-inline-item ms-1"><Link to="#" className="rounded"><i className="uil uil-instagram align-middle" title="instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        )
    
};