import React from "react";
import TinySlider from "tiny-slider-react";
import { Container, Row } from "reactstrap";

import 'tiny-slider/dist/tiny-slider.css';

const settings = {
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 12,
    responsive: {
        992: {
            items: 3
        },

        767: {
            items: 2
        },

        320: {
            items: 1
        },
    },
};

export default function SectionTestimonial() {
    const review = [
        {
            id: '1',
            name: 'Sukardi',
            designation: "Pemilik Kios Tani",
            description: "Setelah menggunakan Nadi, Kios Tani memiliki pengiriman yang lebih baik dari 10 - 14 hari menjadi 1 - 3 hari pengiriman saat membeli persediaan."
        },
        {
            id: '2',
            name: 'Nurul Hidayah',
            designation: "Pemilik Kios Tani",
            description: "Kios Tani berhasil meningkatkan Stock Keeping Unitnya hingga 30% - 50%."
        }, {
            id: '3',
            name: 'Wayan Bagus',
            designation: "Pemilik Kios Tani",
            description: "Memperkuat rantai pasokan input pertanian melalui digitalisasi dan integrasi dengan layanan keuangan, telah memberikan dampak positif yang signifikan bagi Kios Tani dan Petani."
        }, {
            id: '4',
            name: 'Siti Maesaroh',
            designation: "Pemilik Kios Tani",
            description: "Nadi telah mengubah cara kami mendukung para petani. Kini, kami lebih dari sekadar penjual, kami adalah mitra bagi petani untuk mencapai hasil yang lebih baik."
        },
        {
            id: '5',
            name: 'Joko Sulistyo',
            designation: "Pemilik Kios Tani",
            description: "Kerjasama dengan Nadi membawa dampak yang luar biasa. Sistem mereka memudahkan kami dalam mendapatkan akses ke berbagai jenis benih dan pupuk berkualitas tinggi."
        },
    ]

    return (
        <>
            <section className="section bg-light" id="testimonial">
                <Container>
                    <Row className="justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center mb-4 pb-2">
                                <h4 className="title mb-3">Dampak Sosial Ekonomi kami</h4>
                                <p className="text-muted para-desc mx-auto mb-0">Bersama dalam upaya pemberdayaan usaha lokal untuk meningkatkan ketahanan pangan.</p>
                            </div>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-12 mt-4">
                            <div className="tiny-three-item">
                                <TinySlider settings={settings} className="tiny-slide">
                                    {review.map((el, index) => (
                                        <div className="customer-testi m-1" key={index}>
                                            <div className="content p-3 shadow rounded bg-white position-relative">
                                                <ul className="list-unstyled mb-0 text-warning">
                                                    <li className="list-inline-item"><i className="mdi mdi-star"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star"></i></li>
                                                </ul>
                                                <p className="text-muted mt-2">{el.description}</p>
                                            </div>
                                            <div className="text-center mt-3">
                                                <p className="text-primary mt-3 mb-0">{el.name} <small className="text-muted d-block ms-2">{el.designation}</small></p>
                                            </div>
                                        </div>
                                    ))}
                                </TinySlider>
                            </div>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}
