import React, { useState } from 'react';
import * as Icon from 'react-feather';
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import { Col, Row, Card, CardBody, Container } from "reactstrap";
import { Link } from 'react-router-dom';

import Image1 from "../assets/images/portfolio/nadi-portfolio-1.jpg";
import Image2 from "../assets/images/portfolio/nadi-portfolio-2.jpg";
import Image3 from "../assets/images/portfolio/nadi-portfolio-3.jpg";
import Image4 from "../assets/images/portfolio/nadi-portfolio-4.jpg";

export default function SectionProject() {
   
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);


    const images = [
        Image1,
        Image2,
        Image3,
        Image4,
    ];

    return (
        <>
            <section className="section" id="project">
                <Container>
                    <Row className="justify-content-center">
                        <Col>
                            <div className="section-title text-center mb-4 pb-2">
                                <h4 className="title mb-3">Karya & Proyek Kami</h4>
                                <p className="text-muted mx-auto para-desc mb-0">Kami mengintegrasikan teknologi dan inovasi untuk membuka peluang pasar yang lebih luas.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        {images.map((item, index) => (
                            <Col lg={3} md={6} className="col-12 mt-4 pt-2" key={index}>
                                <Card className="border-0 project project-primary position-relative d-block overflow-hidden rounded">
                                    <CardBody className="p-0">
                                        <img src={item} className="img-fluid" alt="workimage" />
                                        <div className="overlay-work bg-dark"></div>
                                        <div className="icons text-center">
                                            <Link to="#" onClick={() => setIsOpen(true)} className="btn btn-icon btn-pills lightbox">
                                                <Icon.Camera className="fea icon-sm image-icon" /></Link>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <>
                        {isOpen && (
                            <Lightbox
                                mainSrc={images[photoIndex]}
                                nextSrc={images[(photoIndex + 1) % images.length]}
                                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                onCloseRequest={() => setIsOpen(false)}
                                onMovePrevRequest={() =>
                                    setPhotoIndex(
                                        (photoIndex + images.length - 1) % images.length,
                                    )
                                }
                                onMoveNextRequest={() =>
                                    setPhotoIndex(
                                        (photoIndex + 1) % images.length,
                                    )
                                }
                            />
                        )}
                    </>
                </Container>
            </section>
        </>
    )
}